import React from "react";

const HeadsetIcon = ({ className = "" }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill="currentColor"
    xmlSpace="preserve"
  >
    <path d="M475.535,216.495c-12.803-8.25-27.656-12.611-42.955-12.611h-21.29c-8.284,0-15,6.716-15,15v159.35    c0,8.284,6.716,15,15,15h21.29c43.793,0,79.42-35.628,79.42-79.421v-30.51C512,256.17,498.366,231.194,475.535,216.495z" />
    <path d="M429.226,422.961h-3.779c-16.314,32.363-49.858,54.616-88.506,54.616c-8.284,0-15,6.716-15,15s6.716,15,15,15    c57.195,0,105.81-37.405,122.691-89.033C449.99,421.413,439.786,422.961,429.226,422.961z" />
    <path d="M256,4.423c-103.948,0-190.482,75.925-207.097,175.227c10.146-3.391,20.8-5.231,31.64-5.455    C98.862,94.245,170.574,34.423,256,34.423s157.138,59.822,175.456,139.773c10.84,0.224,21.493,2.064,31.64,5.455    C446.482,80.348,359.948,4.423,256,4.423z" />
    <path d="M100.71,203.884H79.42c-15.299,0-30.152,4.36-42.949,12.607C13.634,231.194,0,256.171,0,283.304v30.51    c0,43.792,35.628,79.42,79.42,79.42h21.29c8.284,0,15-6.716,15-15v-159.35C115.71,210.6,108.994,203.884,100.71,203.884z" />
  </svg>
);
export default HeadsetIcon;
