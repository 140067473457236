export const SET_PAYMENT_STEP = "SET_PAYMENT_STEP";
export const SET_STRIPE_INFO = "SET_STRIPE_INFO";
export const SET_CARD_BRAND = "SET_CARD_BRAND";
export const CHECK_PAYMENT_RESPONSE = "CHECK_PAYMENT_RESPONSE";
export const SET_PAYMENT_SUCCESS = "SET_PAYMENT_SUCCESS";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_SKIP_PAYMENT = "SET_SKIP_PAYMENT";
export const SET_CUSTOMER_ID = "SET_CUSTOMER_ID";
export const SET_FAILED_PAYMENT_ERROR = "SET_FAILED_PAYMENT_ERROR";
export const SET_LOADING_PAY_STATUS = "SET_LOADING_PAY_STATUS";
export const SET_CARD_NUMBER_COMPLETED = "SET_CARD_NUMBER_COMPLETED";
export const SET_CARD_NUMBER_ERROR = "SET_CARD_NUMBER_ERROR";
export const SET_EXPIRY_DATE_COMPLETED = "SET_EXPIRY_DATE_COMPLETED";
export const SET_EXPIRY_DATE_ERROR = "SET_EXPIRY_DATE_ERROR";
export const SET_CVV_COMPLETED = "SET_CVV_COMPLETED";
export const SET_CVV_ERROR = "SET_CVV_ERROR";
export const SET_CARD_TYPE = "SET_CARD_TYPE";
export const SET_FINISHED = "SET_FINISHED";
export const SET_STRIPE_TOKEN = "SET_STRIPE_TOKEN";
export const CLEAR_PAYMENT_DETAILS = "CLEAR_PAYMENT_DETAILS"
