import React from 'react';

export const taskIcon = () => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 55.999 55.999" width="15px" height="15px">

    <path d="M55.125,32.588l-2.85-2.85c-1.128-1.127-3.092-1.127-4.221,0L32.664,45.129l-2.121,7.779l-0.519,0.519
      c-0.388,0.388-0.389,1.014-0.006,1.405l-0.005,0.02l0.019-0.005c0.194,0.19,0.446,0.288,0.699,0.288
   c0.256,0,0.512-0.098,0.707-0.293l0.52-0.52l7.778-2.121l15.39-15.391C56.29,35.646,56.29,33.752,55.125,32.588z M34.973,45.649
   l10.243-10.242l4.243,4.242L39.216,49.892L34.973,45.649z M34.07,47.574l3.22,3.22l-4.428,1.208L34.07,47.574z M53.711,35.396
   l-2.839,2.839l-4.243-4.242l2.839-2.839c0.373-0.373,1.022-0.372,1.394-0.001l2.85,2.85C54.096,34.386,54.096,35.012,53.711,35.396
   z"/>
 <path d="M27,43.865c-0.552,0-1-0.447-1-1s0.448-1,1-1h4.686l13-13H27c-0.552,0-1-0.447-1-1s0.448-1,1-1h18
      c0.46,0,0.831,0.317,0.948,0.74c1.13-1.123,2.627-1.74,4.218-1.74c1.418,0,2.76,0.492,3.834,1.393V13.04
   c0-6.714-5.462-12.176-12.176-12.176H12.176C5.462,0.865,0,6.326,0,13.04v29.648c0,6.714,5.462,12.176,12.176,12.176h14.724l3-11
   H27z M27,12.865h18c0.552,0,1,0.447,1,1s-0.448,1-1,1H27c-0.552,0-1-0.447-1-1S26.448,12.865,27,12.865z M20.688,38.515
   l-6.857,7.999c-0.197,0.23-0.477,0.35-0.76,0.35c-0.209,0-0.42-0.065-0.6-0.2L7.9,43.235c-0.442-0.331-0.531-0.958-0.2-1.399
   c0.332-0.442,0.958-0.53,1.4-0.2l3.822,2.866l6.248-7.287c0.359-0.42,0.992-0.468,1.41-0.108
   C20.999,37.465,21.047,38.096,20.688,38.515z M20.688,23.515l-6.857,8c-0.197,0.23-0.477,0.35-0.76,0.35
   c-0.209,0-0.42-0.065-0.6-0.2L7.9,28.236c-0.442-0.331-0.531-0.958-0.2-1.399c0.332-0.442,0.958-0.531,1.4-0.2l3.822,2.866
   l6.248-7.288c0.359-0.42,0.992-0.468,1.41-0.108C20.999,22.465,21.047,23.096,20.688,23.515z M20.688,9.515l-6.857,8
   c-0.197,0.23-0.477,0.35-0.76,0.35c-0.209,0-0.42-0.065-0.6-0.2L7.9,14.236c-0.442-0.331-0.531-0.958-0.2-1.399
   c0.332-0.442,0.958-0.531,1.4-0.2l3.822,2.866l6.248-7.288c0.359-0.42,0.992-0.468,1.41-0.108
   C20.999,8.465,21.047,9.096,20.688,9.515z"/>

  </svg>


);

export default taskIcon;


