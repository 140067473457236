import React from 'react';

export const moreIcon = () => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 490 490" width="20px" height="20px">

    <path d="M245,185.5c-32.8,0-59.5,26.7-59.5,59.5s26.7,59.5,59.5,59.5s59.5-26.7,59.5-59.5S277.8,185.5,245,185.5z M280,245
      c0,19.3-15.7,35-35,35s-35-15.7-35-35s15.7-35,35-35S280,225.7,280,245z"/>
   <path d="M185.5,430.5c0,32.8,26.7,59.5,59.5,59.5s59.5-26.7,59.5-59.5S277.8,371,245,371S185.5,397.7,185.5,430.5z M280,430.5
      c0,19.3-15.7,35-35,35s-35-15.7-35-35s15.7-35,35-35S280,411.2,280,430.5z"/>
   <path d="M185.5,59.5c0,32.8,26.7,59.5,59.5,59.5s59.5-26.7,59.5-59.5S277.8,0,245,0S185.5,26.7,185.5,59.5z M280,59.5
      c0,19.3-15.7,35-35,35s-35-15.7-35-35s15.7-35,35-35S280,40.2,280,59.5z"/>
     </svg>
);

export default moreIcon;


