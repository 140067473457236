import React from 'react';

export const addIcon = () => (


  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="20px" height="20px" viewBox="0 0 357 357" fill="#fff" >
    <path d="M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z" />
  </svg>




);

export default addIcon;


