import React from 'react';

export const reportAnalyticsIcon = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 334.33 479.35"><title>Reports and analytics</title><path d="M36.33,225.2c6.27,3.09,11.78,6.09,17.52,8.59,65.38,28.45,140.28-13.3,150.62-84,.65-4.44,1-8.94,1.19-13.42.34-7.15-1.51-9-8.51-9H103.56V72.72h25.8c0,7.06,0,14.16,0,21.26,0,6.46,1.86,8.35,8.28,8.35q49,0,98,0c6.65,0,8.86-2.09,8.44-8.68-.44-7-1.38-13.89-2.12-21.08h19.39v5.26q0,30.5,0,61c0,8,1.58,9.51,9.71,9.52h63.28v5.4q0,150.7,0,301.41c0,14.59-9.53,24.17-24.05,24.18q-124.45,0-248.92,0c-14.76,0-25-9.86-25-24q0-113.22,0-226.43ZM82.6,423H283.93V411.6H82.6Zm0-49H199.09V362.61H82.55Z" transform="translate(-0.02)" /><path d="M91.36,37.33V53.65q0,38.73,0,77.45c0,6.8,1.39,8.23,8.14,8.23q42.72,0,85.45,0h8.59a93.26,93.26,0,0,1-20.23,53.92c-17,21.63-39.41,34.14-66.72,36.85C49.82,235.72,1.7,193,.06,135.45-1.46,82.15,42.42,38.69,91.36,37.33Z" transform="translate(-0.02)" /><path d="M141.48,90V0c41,.34,87.65,36.33,90.15,90Z" transform="translate(-0.02)" /><path d="M326.56,136.2H273.63V81.46Z" transform="translate(-0.02)" /></svg>

);

export default reportAnalyticsIcon;


